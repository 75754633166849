@font-face {
    font-family: "ApercuPro";
    src: local("Apercu-Light-Pro"), url("./ApercuPro/Apercu-Light-Pro.ttf") format("truetype");
    font-display: block;
    font-weight: 300;
  }

  @font-face {
    font-family: "ApercuPro";
    src: local("Apercu-Regular-Pro"), url("./ApercuPro/Apercu-Regular-Pro.ttf") format("truetype");
    font-display: block;
    font-weight: 500;
  }
  
  @font-face {
    font-family: "ApercuPro";
    src: local("Apercu-Bold-Pro"), url("./ApercuPro/Apercu-Bold-Pro.ttf") format("truetype");
    font-display: block;
    font-weight: 700;
  }

  @font-face {
    font-family: "ApercuPro";
    src: local("Apercu-Black-Pro"), url("./ApercuPro/Apercu-Black-Pro.ttf") format("truetype");
    font-display: block;
    font-weight: 900;
  }